import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import '../page-styles/reset-password.scss';

import axios from 'axios';
import Logo from '../images/Layout/LogoElife.svg';
import FormButton from '../shared/UIKit/FormButton';
import { baseUrl } from '../shared/APIs';
import InputField from '../shared/UIKit/InputField';

function ResetPassword({ location }) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    if (fieldValues?.password?.length < 6) {
      setResponse('ERROR_LEN');
      setLoading(false);
      return false;
    }

    if (fieldValues?.password !== fieldValues?.passwordConfirmation) {
      setResponse('ERROR_DIFF');
      setLoading(false);
      return false;
    }

    try {
      await axios.post(`${baseUrl}/auth/reset-password`, {
        ...fieldValues,
        code,
      });
      setResponse('SUCCESS');
    } catch (error) {
      if (error?.response?.status === 400) {
        setResponse('EXPIRED');
      } else {
        setResponse('ERROR');
      }
    }
    setLoading(false);
    return false;
  }

  useEffect(() => {
    if (!response) return;
    setTimeout(() => {
      if (response === 'EXPIRED') {
        return;
      }
      setResponse();
    }, 3000);
  }, [response]);

  return (
    <div className="reset-password">
      <div className="w-450">
        <Link to="/" className="logo-container">
          <img src={Logo} alt="Elife Logo" />
        </Link>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <div className="position-relative">
            <InputField
              type="password"
              name="password"
              label="mot de passe"
              placeholder="6+ strong character"
              required
            />
          </div>
          <div className="position-relative">
            <InputField
              type="password"
              name="passwordConfirmation"
              label="mot de passe"
              placeholder="Confirmez le mot de passe"
              required
            />
          </div>
          {response === 'ERROR_LEN' && (
            <span className="error">
              Votre mot de passe doit comporter au moins 6 caractères
            </span>
          )}
          {response === 'EXPIRED' && (
            <span className="error">
              Le lien de réinitialisation du mot de passe a expiré,
              <Link to="/forget-password"> réessayez</Link>
            </span>
          )}
          {response === 'ERROR_DIFF' && (
            <span className="error">
              La confirmation du mot de passe ne correspond pas
            </span>
          )}
          {response === 'ERROR' && (
            <span className="error">
              Une erreur s&apos;est produite. Veuillez réessayer
            </span>
          )}
          {response === 'SUCCESS' && (
            <span className="success">
              Votre mot de passe a été réinitialisé avec succès
            </span>
          )}
          <div className="button-container">
            <FormButton text="Enregistrer" loading={loading} />
          </div>
        </form>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  location: PropTypes.shape,
};
ResetPassword.defaultProps = {
  location: {},
};

export default ResetPassword;
